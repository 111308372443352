import React from 'react'

import Link from 'src/components/link/index'
import Modal from 'src/components/modal/index'

import styles from './modal.module.scss'

const AdmissionModal = ({ isOpen, close }) => {
	const send = (name) => {
		if (`gtag` in window) {
			// window.ga('send', 'event', 'formlink', 'click', name, 1)
			window.gtag(`event`, `click`, {
				event_category: `formlink`,
				event_label: name,
				value: 1,
			})
		}
	}

	return (
		<Modal className={styles.modal} isOpen={isOpen}>
			<div className={styles.wrapper}>
				<button onClick={close} aria-label="close" className={styles.back}></button>
				<div className={styles.content}>
					<ul className={styles.pricelist}>
						<li>
							<Link
								className={styles.link}
								to="https://entry.thefit.jp/join/000101/"
								onClick={() => send(`toritsudai`)}
							>
								<span className={styles.thefit}>WE ARE THE FIT</span>
								<span className={styles.gymName}>都立大学店</span>
								<span className={styles.monthlyPrice}>
									<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
								</span>
								<div className={styles.button}>入会はこちら</div>
							</Link>
						</li>
						<li>
							<Link
								className={styles.link}
								to="https://entry.thefit.jp/join/000102/"
								onClick={() => send(`ekoda`)}
							>
								<span className={styles.thefit}>WE ARE THE FIT</span>
								<span className={styles.gymName}>江古田店</span>
								<span className={styles.monthlyPrice}>
									<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
								</span>
								<div className={styles.button}>入会はこちら</div>
							</Link>
						</li>
						<li>
							<Link className={styles.link} to="https://thefit.jp/news/join" onClick={() => send(`yoga`)}>
								<span className={styles.thefit}>WE ARE THE FIT</span>
								<span className={styles.gymName}>用賀店</span>
								<span className={styles.monthlyPrice}>
									<span>¥7,980</span> / 月<small>（税込 ¥8,778）</small>
								</span>
								<div className={styles.button}>入会はこちら</div>
							</Link>
						</li>
					</ul>
				</div>
				<button onClick={close} aria-label="close" className={styles.close}></button>
			</div>
		</Modal>
	)
}

export default AdmissionModal
