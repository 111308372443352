const calcScrollBarWidth = () => {
	const div1 = document.createElement('div')
	const div2 = document.createElement('div')

	div1.style.cssText = `
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    visibility: hidden;
  `

	div2.style.cssText = `
    height: 1px;
  `

	div1.appendChild(div2)
	document.body.appendChild(div1)
	const scrollBarWidth = 50 - div2.clientWidth
	document.body.removeChild(div1)

	return scrollBarWidth
}

export { calcScrollBarWidth }
