import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { CSSTransition } from 'react-transition-group'
import { calcScrollBarWidth } from 'src/lib/util'

import styles from './index.module.scss'
import transitoin from './transition.module.scss'

const Modal = ({ className, isOpen, children }) => {
	useEffect(() => {
		return () => {
			document.documentElement.style.overflow = ''
			document.documentElement.style.setProperty('--scroll-bar-width', '')
		}
	}, [])

	useEffect(() => {
		if (isOpen) {
			document.documentElement.style.overflow = 'hidden'
			document.documentElement.style.setProperty('--scroll-bar-width', calcScrollBarWidth() + 'px')
		} else {
			document.documentElement.style.overflow = ''
			document.documentElement.style.setProperty('--scroll-bar-width', '')
		}
	}, [isOpen])

	if (typeof window !== `undefined`) {
		const _className = styles.modal + (className ? ` ${className}` : ``)

		return ReactDOM.createPortal(
			<CSSTransition in={isOpen} timeout={500} unmountOnExit classNames={transitoin}>
				<div className={_className}>{children}</div>
			</CSSTransition>,
			document.body
		)
	} else {
		return null
	}
}

export default Modal
